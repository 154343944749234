import { Box, Text } from "@chakra-ui/react"

const AboutUs = () => {
    return (
        <Box paddingTop="40px" width={{ base: "85%", md: "75%" }} ml={{ base: "30px", md: "70px" }} fontSize={{ base: "18px", md: "35px" }}>
            <Text color="#666666">Your future in Software Engineering starts here, at Paradigm Coding Academy. We're more than an academy; we're your dedicated partners in engineering excellence.</Text>
            <Text color="#666666" mt="20px">Our passion is your success. From ideation to implementation, we offer a comprehensive solution to help you excel.</Text>
            <Text color="#666666" mt="20px" mb="40px">Embark on your extraordinary software engineering journey with us today and let's shape the future together.</Text>
        </Box>
    )
}

export default AboutUs