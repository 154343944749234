import { Box, Heading, Text, CardBody, Card, useMediaQuery } from "@chakra-ui/react"
import Tag from "./Tag"
import Progress from "./Progress";
import ProgressMobile from "./ProgressMobile";

const FE = () => {
    const [isMobile] = useMediaQuery("(max-width: 768px)");
    const tags = [
        { label: 'JavaScript', color: 'yellow' },
        { label: 'React', color: 'blue' },
        { label: 'Node', color: 'green' },
        { label: 'HTML', color: 'red' },
        { label: 'CSS', color: 'purple' },
        { label: 'TypeScript', color: 'teal' },
        { label: 'Next JS', color: 'orange' },
        { label: 'Chakra UI', color: 'pink' },
        { label: 'Responsive Design', color: 'pink' },
        { label: 'Mobile Development', color: 'cyan' },
        { label: 'React Native', color: 'pink' },
        { label: 'Redux', color: 'green' },
        { label: 'Context API', color: 'purple' },
        { label: 'SDLC', color: 'blue' },
        { label: 'Soft Skills', color: 'green' },
        { label: 'Interview Preparation', color: 'red' },
        { label: 'Algorithms', color: 'blue' },
        { label: 'GIT', color: 'orange' },
        { label: 'Best Practices', color: 'teal' },
        { label: 'Testing', color: 'green' },
    ];

    return (
        <Box>
            <Card align='left'>
                <CardBody>
                    <Text fontSize={{ base: 'sm', md: 'lg' }}>Front-End Development is an extraordinary career choice filled with boundless opportunities for growth. In today's dynamic digital world, the demand for talented Front-End Developers is skyrocketing, offering enduring prospects for advancement. Your path in Front-End Development is a thrilling adventure of perpetual learning and creativity, where your imagination is the compass leading you towards uncharted horizons. It's not merely a career; it's an opportunity to shape the ever-evolving digital future while nurturing your professional growth without limits.</Text>
                </CardBody>
            </Card>
            <Box>
                <Text fontSize="2xl" mt="20px" fontWeight="bold" mb="20px">Skills you will gain: </Text>
                <div className="tag-container">
                    {tags.map((tag, index) => (
                        <Tag key={index} label={tag.label} color={tag.color} />
                    ))}
                </div>
            </Box>
            {isMobile ? <ProgressMobile /> : <Progress />}
        </Box>
    )
}

export default FE;