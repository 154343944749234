import React, { useState } from 'react';
import {
    Box,
    Heading,
    SimpleGrid,
    Button
} from '@chakra-ui/react';
import ValuesCard from './ValuesCard';

const cardData = [
    {
        title: 'Live Classes',
        description:
            'During our live sessions, we focus on giving every student individual attention and support. With 1:1 support, instant feedback, and collaborative opportunities, we ensure a tailored learning experience that leads to your success.',
    },
    {
        title: 'Small Classes, Big Success',
        description:
            'We believe in quality over quantity. Our limited class sizes guarantee that you receive the attention you deserve. No one gets lost in the crowd here. Every student matters, and we\'re committed to your success.',
    },
    {
        title: 'Cutting-Edge Curriculum',
        description:
            'The tech world evolves rapidly, and so do we. Our courses are always up-to-date with the latest and most cutting-edge technologies. We prepare you for the challenges of tomorrow, not just today.',
    },
    {
        title: 'Tailored Learning',
        description:
            'One size does not fit all. We understand that every student has unique strengths and goals. That\'s why we craft personalized learning plans for each individual. Your journey is as unique as you are.',
    },
    {
        title: 'Progress Monitoring',
        description:
            'We don\'t just teach and forget. We track your progress every step of the way, providing guidance and support when you need it most. Your success is our success.',
    },
    {
        title: 'Job Market Readiness',
        description:
            'Your dream job is just around the corner, and we\'re here to help you seize it. We provide the skills and knowledge that employers demand, giving you a competitive edge in the job market.',
    },
    {
        title: 'Expert Instructors',
        description:
            'Our team of experienced instructors is more than just educators; they\'re mentors and champions of your success. With a commitment to your growth, they\'ll guide you every step of the way.',
    },
    {
        title: 'Collaborative Learning Experience',
        description:
            'At Paradigm Coding Academy, we value the importance of a 1:1 work with instructors to enhance your learning experience. Our dedicated instructors are here to guide you every step of the way, providing personalized support, feedback, and mentorship. Your success is our priority.',
    },
    {
        title: 'Comprehensive Career Support',
        description:
            'At Paradigm Coding Academy, we go beyond coding skills. Our career support services are designed to equip you for success in the job market. From interview preparation and mock interviews to resume building and job placement assistance, we provide a holistic approach to launching your career in software development.',
    },
];

function WhyParadigm() {
    const [showAll, setShowAll] = useState(false);
    const itemsToShow = showAll ? cardData.length : 6;

    return (
        <Box backgroundColor="#F0F0F0" paddingX={{ base: 6, md: "70px" }} pt={10} pb={10}>
            <Heading size={{ base: 'md', md: "xl" }} mb={6}>Why Choose Paradigm?</Heading>
            <Heading size={{ base: 'sm', md: "md" }} mb={10}>At Paradigm, we pride ourselves on setting new standards in the industry. Here's why we stand out:</Heading>
            <SimpleGrid
                columns={{ base: 1, md: 2, lg: 3 }}
                spacing={6}
            >
                {cardData.slice(0, itemsToShow).map((card, index) => (
                    <ValuesCard key={index} title={card.title} description={card.description} />
                ))}
            </SimpleGrid>
            {!showAll && (
                <Box textAlign="center">
                    <Button mt={4} onClick={() => setShowAll(true)} colorScheme='blue'>
                        View More
                    </Button>
                </Box>
            )}
        </Box>
    );
}

export default WhyParadigm;
