import React from 'react';
import {
    Box,
    Heading,
    SimpleGrid,
    Center,
    VStack,
} from '@chakra-ui/react';
import TeamCard from "./TeamCard"
import Talgat from "../../images/Talgat.png"
import Askat from "../../images/Askat.png"
import Adil from "../../images/Adil.png"

const Team = () => {
    return (
        <Box backgroundColor="#D3D3D3" textAlign="center" pt={10} pb={10}>
            <Heading as='h2' size='2xl' color="#00000" mb={6}>Our Team</Heading>
            <Center padding="20px">
                <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing="50px">
                    <TeamCard
                        title="Talgat"
                        description="Software Engineer with over 8 years of experience in the IT industry, specializing in front-end development for the past 4 years."
                        url={Talgat}
                    />
                    <TeamCard
                        title="Askat"
                        description="Senior Software Engineer with over 10 years of experience in fullstack development, big data engineering, and mobile development."
                        url={Askat}
                    />
                    <TeamCard
                        title="Adil"
                        description="Backend Software Developer with expertise in Node.js and a college programming instructor."
                        url={Adil}
                    />
                </SimpleGrid>
            </Center>
        </Box>
    );
}

export default Team;