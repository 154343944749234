import {
  Box,
  Text,
  IconButton,
  useDisclosure,
  VStack,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  HStack,
  Stack
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import Logo from "./Logo/Logo";
import NavLinks from "./NavLinks/NavLinks"
import { useEffect, useState } from "react";
import LogoWhite from "./Logo/LogoWhite";

const Header = ({ activeLink, setActiveLink }) => {
  const [isSticky, setIsSticky] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleScroll = () => {
    if (window.scrollY > 200) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    // Set hasLoaded to true after the component has mounted
    setHasLoaded(true);
  }, []);

  const navLinksStyles = {
    position: isSticky ? "fixed" : "static",
    top: isSticky ? "0" : "auto",
    zIndex: 999,
    width: "100%",
    backgroundColor: isSticky ? "white" : "transparent",
  };

  return (
    <Box backgroundColor="#ebebeb">
      <Box
        display="flex"
        justifyContent="space-between"
        style={navLinksStyles}
        p={4}
    
      >
        <Logo />
        <IconButton
          icon={<HamburgerIcon />}
          display={{ base: "block", md: "none" }}
          onClick={onOpen}
        />
        <HStack spacing={4} display={{ base: "none", md: "flex" }}>
          <NavLinks activeLink={activeLink} setActiveLink={setActiveLink} />
        </HStack>
      </Box>
      <Stack spacing={4} marginX={{ base: "30px", md: "60px" }} mt="100px">
        <Text
          className={`header-text1${hasLoaded ? " loaded" : ""}`}
          fontSize={{ base: "38px", md: "100px" }} // Adjust font size for mobile
          color="#010101"
          fontWeight="extrabold"
          lineHeight="none"
        >
          Paradigm Coding Academy
        </Text>
        <Text
          className={`header-text2${hasLoaded ? " loaded" : ""}`}
          fontSize={{ base: "38px", md: "100px" }} // Adjust font size for mobile
          color="#ff6b6b"
          fontWeight="extrabold"
          lineHeight="none"
          mb={{ base: "30px", md: "50px" }}
        >
          Empowering Dreams, Crafting Excellence
        </Text>
      </Stack>

      {/* Drawer Menu */}
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="full">
        <DrawerOverlay>
          <DrawerContent backgroundColor="#363636" color="white">
            <DrawerCloseButton />
            <DrawerHeader>
              <LogoWhite />
            </DrawerHeader>
            <DrawerBody>
              <VStack spacing={4} align="start">
                <NavLinks activeLink={activeLink} setActiveLink={setActiveLink} isMobile={true} onClose={onClose}/>
              </VStack>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </Box>
  );
};

export default Header;
