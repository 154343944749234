import './App.css';
import Header from './components/Header/Header'
import AboutUs from './components/AboutUs/AboutUs';
import Services from './components/Services/Services';
import Footer from './components/Footer/Footer';
import WhyParadigm from './components/WhyParadigm/WhyParadigm';
import Skills from './components/Skills/Skills';
import { Element } from "react-scroll";
import { useState } from 'react';
import Progress from './components/Services/Progress';
import ProgressMobile from './components/Services/ProgressMobile';
import { Box, Heading, useMediaQuery } from '@chakra-ui/react';
import Team from './components/Team/Team';

function App() {
  const [activeLink, setActiveLink] = useState(null);
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  return (
    <div className="App">
      <Element name="header">
        <Header activeLink={activeLink} setActiveLink={setActiveLink} />
      </Element>
      {/* <Element name="aboutUs"> */}
        <AboutUs />
      {/* </Element> */}
      <Element name="values">
        <WhyParadigm />
      </Element>
      <Element name="courses">
        <Services />
      </Element>
      <Element name="skills">
        <Skills />
      </Element>
      <Element name="team">
        <Team />
      </Element>
      <Element name="roadmap">
        <Box backgroundColor="#F9F9F9">
          {isMobile ? < ProgressMobile/> : <Progress />}
        </Box>
      </Element>
      <Element name="contact">
        <Footer />
      </Element>
    </div>
  );
}

export default App;
