import React from "react";
import { VStack, Text, Box, HStack, useMediaQuery } from "@chakra-ui/react";
import { Link as ScrollLink } from "react-scroll";

const NavLinks = ({ activeLink, setActiveLink, isMobile, onClose }) => {
  const isLinkActive = (id) => activeLink === id;
  const [isIPadMini] = useMediaQuery("(max-width: 1024px)");

  return (
    <Box width="100%"> {/* Align links to the right for mobile */}
      {isMobile ? (
        <VStack
          spacing={4}
          align="start"
          fontSize="35"
          fontWeight="extrabold"
          mb="25px"
          alignItems="flex-end"
          color="white"
        >
          <ScrollLink
            to="header"
            smooth={true}
            duration={0}
            offset={-100}
            onClick={onClose}
          >
            <Text className={isLinkActive("aboutUs") ? "underline" : ""}>About</Text>
          </ScrollLink>

          <ScrollLink
            to="values"
            smooth={true}
            duration={0}
            offset={-100}
            onClick={onClose}
          >
            <Text className={isLinkActive("values") ? "underline" : ""}>Values</Text>
          </ScrollLink>

          <ScrollLink
            to="courses"
            smooth={true}
            duration={0}
            offset={-100}
            onClick={onClose}
          >
            <Text className={isLinkActive("courses") ? "underline" : ""}>Courses</Text>
          </ScrollLink>

          <ScrollLink
            to="skills"
            smooth={true}
            duration={0}
            offset={-100}
            onClick={onClose}
          >
            <Text className={isLinkActive("skills") ? "underline" : ""}>Skills</Text>
          </ScrollLink>

          <ScrollLink
            to="team"
            smooth={true}
            duration={0}
            offset={-100}
            onClick={onClose}
          >
            <Text className={isLinkActive("team") ? "underline" : ""}>Team</Text>
          </ScrollLink>

          <ScrollLink
            to="roadmap"
            smooth={true}
            duration={0}
            offset={-100}
            onClick={onClose}
          >
            <Text className={isLinkActive("roadmap") ? "underline" : ""}>Roadmap</Text>
          </ScrollLink>

          <ScrollLink
            to="contact"
            smooth={true}
            duration={0}
            offset={-100}
            onClick={onClose}
          >
            <Text className={isLinkActive("contact") ? "underline" : ""}>Contact</Text>
          </ScrollLink>
        </VStack>
      ) : (
        // For desktop screens, maintain the original horizontal layout
        <HStack
          spacing={5}
          align="start"
          color="#010101"
          mr="70px"
          fontSize={isIPadMini ? "15px" : "30px"}
        >
          <ScrollLink
            to="header"
            smooth={true}
            duration={0}
            offset={-150}
            onClick={onClose}
          >
            <Text className={isLinkActive("aboutUs") ? "underline" : ""}>About</Text>
          </ScrollLink>

          <ScrollLink
            to="values"
            smooth={true}
            duration={0}
            offset={-150}
            onClick={onClose}
          >
            <Text className={isLinkActive("values") ? "underline" : ""}>Values</Text>
          </ScrollLink>

          <ScrollLink
            to="courses"
            smooth={true}
            duration={0}
            offset={-100}
            onClick={onClose}
          >
            <Text className={isLinkActive("courses") ? "underline" : ""}>Courses</Text>
          </ScrollLink>

          <ScrollLink
            to="skills"
            smooth={true}
            duration={0}
            offset={-100}
            onClick={() => setActiveLink("skills")}
          >
            <Text className={isLinkActive("skills") ? "underline" : ""}>Skills</Text>
          </ScrollLink>

          <ScrollLink
            to="team"
            smooth={true}
            duration={0}
            offset={-100}
            onClick={onClose}
          >
            <Text className={isLinkActive("team") ? "underline" : ""}>Team</Text>
          </ScrollLink>

          <ScrollLink
            to="roadmap"
            smooth={true}
            duration={0}
            offset={-100}
            onClick={() => setActiveLink("roadmap")}
          >
            <Text className={isLinkActive("contact") ? "underline" : ""}>Roadmap</Text>
          </ScrollLink>

          <ScrollLink
            to="contact"
            smooth={true}
            duration={0}
            offset={-100}
            onClick={() => setActiveLink("contact")}
          >
            <Text className={isLinkActive("contact") ? "underline" : ""}>Contact</Text>
          </ScrollLink>
        </HStack>
      )}
    </Box>
  );
};

export default NavLinks;