import React from 'react';
import {
    Heading,
    Card,
    CardHeader,
    CardBody,
    Text,
} from '@chakra-ui/react';

const ValuesCard = ({title, description}) => {
    return (
        <Card
            variant='outline'
            className='value-title'
        >
            <CardHeader>
                <Heading size={{ base: 'sm', md: "md" }} fontWeight="extrabold" className="heading-color-change">{title}</Heading>
            </CardHeader>
            <CardBody>
                <Text fontSize={{ base: 'sm', md: "lg" }} mt="-5">
                   {description}
                </Text>
            </CardBody>
        </Card>
    )
}

export default ValuesCard