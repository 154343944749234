import React from 'react';
import { Box, Text, Flex, Center } from '@chakra-ui/react';
import { PhoneIcon, EmailIcon } from '@chakra-ui/icons';
import Logo from '../Header/Logo/Logo';

const Footer = () => {
    return (
        <Box backgroundColor="#363636" width="100%" paddingY="20px">
            <Center flexDirection="column" color="white" textAlign="center" fontSize={{ base: "14px", md: "20px" }} lineHeight="none">
                <Text mt={4} mb={10}>Paradigm Coding Academy</Text>
                <Flex alignItems="center" mb="2">
                    <PhoneIcon w={{ base: 3, md: 6 }} h={{ base: 3, md: 5 }} color="#F5F5DC" mr="2" />
                    <Text>+1 314-934-4836</Text>
                </Flex>
                <Flex alignItems="center" >
                    <EmailIcon w={{ base: 3, md: 6 }} h={{ base: 3, md: 5 }} color="white" mr="2" mt="1px" />
                    <Text>info@paradigmfullstack.com</Text>
                </Flex>
            </Center>
            <Text color="white" textAlign="center" mt="10" fontSize={{ base: "10px", md: "16px" }}>
                All rights reserved @ 2023 | Paradigm Coding Academy
            </Text>
        </Box>
    );
};

export default Footer;
