import React from 'react';
import { HStack, Tag, TagLabel, TagCloseButton } from '@chakra-ui/react';

const TagStack = ({ label, color }) => {
    return (
        <HStack spacing={4}>
            <Tag
                size="lg"
                borderRadius='full'
                variant='solid'
                colorScheme={color}
                margin="10px"
            >
                <TagLabel>{label}</TagLabel>
            </Tag>
        </HStack>
    );
}

export default TagStack;
