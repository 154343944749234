import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Divider,
  Heading,
  Image,
  Stack,
  Text,
  Button,
  ButtonGroup,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  useDisclosure,
  Box
} from "@chakra-ui/react";

const TeamCard = ({ title, description, url }) => {

  return (
    <>
      <Card maxW="sm" className="service-card" pb="20px">
        <CardBody the>
          <Box display="flex" justifyContent="center">
            <Image src={url} alt="Green double couch with wooden legs" borderRadius="3xl" height="300px" />
          </Box>
          <Stack mt="6" spacing="3">
            <Heading size="md">{title}</Heading>
            <Text color="#555555" textAlign="left">{description}</Text>
          </Stack>
        </CardBody>
      </Card>
    </>
  );
};

export default TeamCard;
