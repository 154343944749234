import React from 'react';
import {
    Box,
    Heading,
    SimpleGrid,
    Center,
    VStack,
} from '@chakra-ui/react';
import ServiceCard from "./ServiceCard";
import webCard from '../../images/webCard.jpg';
import mobileCard from '../../images/mobileCard.jpg';
import designCard from '../../images/designCard.jpg';

const Services = () => {
    return (
        <Box backgroundColor="#D3D3D3" textAlign="center" pt={10} pb={10}>
            <Heading as='h2' size='2xl' color="#00000" mb={6}>Available Courses</Heading>
            <Center padding="20px">
                <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing="20px">
                    <ServiceCard
                        title="Front-End Development"
                        description="Join our Front-End Development course to acquire cutting-edge skills and kickstart your career in Software Engineering.  Master the craft of designing user interfaces and delivering exceptional digital experiences."
                        url={webCard}
                    />
                    <ServiceCard
                        title="Mobile Development"
                        description="Unlock the world of Mobile Development and craft innovative apps for the future. Dive into the dynamic realm of mobile technologies and harness your creativity to build cutting-edge mobile applications that make an impact."
                        url={mobileCard}
                    />
                    <ServiceCard
                        title="UI/UX Design"
                        description="Enter the world of UI/UX Design and unleash your creativity in crafting intuitive and visually stunning user experiences. Join us on a journey where design meets functionality, and learn how to create user interfaces that captivate and engage."
                        url={designCard}
                    />
                </SimpleGrid>
            </Center>
        </Box>
    );
}

export default Services;
