import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Divider,
  Heading,
  Image,
  Stack,
  Text,
  Button,
  ButtonGroup,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  useDisclosure,
  Box
} from "@chakra-ui/react";
import MobileDevCard from "./MobileDevCard";
import FE from "./FE";

const ServiceCard = ({ title, description, url }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);

  const handleLearnMoreClick = (title) => {
    setIsDrawerOpen(true);
    onOpen();
    setSelectedCourse(title)
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
    onClose();
  };

  return (
    <>
      <Card maxW="sm" className="service-card">
        <CardBody>
          <Image src={url} alt="Green double couch with wooden legs" borderRadius="lg" />
          <Stack mt="6" spacing="3">
            <Heading size="md">{title}</Heading>
            <Text color="#555555" textAlign="left">{description}</Text>
          </Stack>
        </CardBody>
        <CardFooter alignSelf="center">
          <Button variant="ghost" colorScheme="blue" onClick={() => handleLearnMoreClick(title)} mt="-15px">
            Learn More
          </Button>
        </CardFooter>
      </Card>

      <Drawer isOpen={isOpen} onClose={handleCloseDrawer} size="xl" colorScheme="blue">
        <DrawerOverlay />
        <DrawerContent bg="#ebebeb">
          <DrawerCloseButton />
          <DrawerHeader fontSize="3xl">{title}</DrawerHeader>
          <DrawerBody>
            {selectedCourse === "Front-End Development" ? <FE /> : <Heading size="lg">Coming soon...</Heading>}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default ServiceCard;
