import React from 'react';
import { Box, Image, Stack, Heading, Text, Button, Card, CardBody, CardFooter, HStack } from '@chakra-ui/react';

const MobileDevCard = ({ title, description, imageUrl }) => {

    return (
        <Card
            direction={{ base: 'column', sm: 'row' }}
            overflow='hidden'
            variant='outline'
            mb="30px"
            className='dev-card'
        >
            <Stack>
                <CardBody>
                    <HStack className='dev-card-header'>
                        {imageUrl}
                        <Heading size='md' ml={title === "Algorithms and Data Structures" || title === "Soft Skills" && '5px'}>{title}</Heading>
                    </HStack>

                    <Text py='2'>
                        {description}
                    </Text>
                </CardBody>
            </Stack>
        </Card>
    );
};

export default MobileDevCard;
